export const users = [
  {
    id: 1,
    firstName: 'Ryan',
    lastName: 'Thompson',
    email: 'ryan-thompson@gmail.com',
    username: 'bigbadryan',
    address: '3527 Retreat Avenue',
    city: 'New York City',
    country: 'United States',
    postalCode: '20540',
    age: '32',
    aboutMe: 'Food ninja. Web enthusiast. Incurable alcohol specialist. Reader. Coffee expert. Bacon nerd. General music practitioner. Problem solver.',
    imgUrl: require('assets/img/theme/team-1-800x800.jpg'),
    friends: 5,
    photos: 10,
    comments: 84,
    title: 'Industrial-organizational psychologist',
    company: 'Brilliant Home Designs',
    relatedUsers: [2, 3, 4, 5],
    onboarding: 86,
    coverUrl: 'url(' + require('assets/img/theme/cover1.jpg') + ')',
    isActive: true,

  },
  {
    id: 2,
    firstName: 'Romina',
    lastName: 'Hadid',
    email: 'romina_hadid@india.com',
    username: 'romina_hadid35',
    address: '4387 Kelly Street',
    city: 'Charlotte',
    country: 'United States',
    postalCode: '07340',
    age: '28',
    aboutMe: 'Music fan. Extreme twitteraholic. Social media fanatic. Internet fanatic. General zombie ninja. Certified bacon buff.',
    imgUrl: require('assets/img/theme/team-2-800x800.jpg'),
    friends: 9,
    photos: 5,
    comments: 72,
    title: 'Software Engineer',
    company: 'Multi Tech Developments',
    relatedUsers: [3, 4, 5],
    onboarding: 64,
    coverUrl: 'url(' + require('assets/img/theme/cover2.jpg') + ')',
    isActive: true,
  },
  {
    id: 3,
    firstName: 'Alexander',
    lastName: 'Smith',
    email: 'smithalexsmith@futureplan.com',
    username: 'alexander_psycho',
    address: '3527 Retreat Avenue',
    city: 'Walnut Creek (CA)',
    country: 'United States',
    postalCode: '04320',
    age: '35',
    aboutMe: 'Coffee advocate. Unapologetic internetaholic. Travel nerd. Devoted tv aficionado. Social media evangelist. Hardcore food junkie.',
    imgUrl: require('assets/img/theme/team-3-800x800.jpg'),
    friends: 5,
    photos: 10,
    comments: 84,
    title: 'Industrial-organizational psychologist',
    company: 'Future Plan',
    relatedUsers: [2, 5],
    onboarding: 35,
    coverUrl: 'url(' + require('assets/img/theme/cover3.jpg') + ')',
    isActive: false,
  },
  {
    id: 4,
    firstName: 'Jessica',
    lastName: 'Doe',
    email: 'jessica-doe@userguiding.com',
    username: 'jessyjes',
    address: '31  Mill Lane',
    city: 'London',
    country: 'United Kingdom',
    postalCode: 'IV13 4TU',
    age: '29',
    aboutMe: 'Bacon expert. Hipster-friendly web lover. Wannabe travel junkie. Tv fanatic. Hardcore student. Professional zombie ninja. Coffee fanatic. Food evangelist.',
    imgUrl: require('assets/img/theme/team-4-800x800.jpg'),
    friends: 5,
    photos: 10,
    comments: 84,
    title: 'Nurse',
    company: 'Bright Hospital London',
    relatedUsers: [1, 3, 5],
    onboarding: 95,
    coverUrl: 'url(' + require('assets/img/theme/cover4.jpg') + ')',
    isActive: true,
  },
  {
    id: 5,
    firstName: 'Efe',
    lastName: 'Celik',
    email: 'efehan@userguiding.com',
    username: 'efe98',
    address: 'İkitelli Hasat Sokak 14/2',
    city: 'Istanbul',
    country: 'Turkey',
    postalCode: '20540',
    age: '40',
    aboutMe: 'Social media aficionado. Coffee specialist. Avid pop culture fan. Lifelong music advocate.',
    imgUrl: require('assets/img/theme/team-5-800x800.jpg'),
    friends: 5,
    photos: 10,
    comments: 84,
    title: 'Customer Success Manager',
    company: 'UserGuiding',
    relatedUsers: [1, 2, 4],
    onboarding: 42,
    coverUrl: 'url(' + require('assets/img/theme/cover5.jpg') + ')',
    isActive: false,
  },
]
